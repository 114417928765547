import  {combineReducers} from 'redux';
import loadingReducer from './loadingReducer';
import queueReducer from './queueReducer';


const rootReducers = combineReducers({
    loadingReducer,
    queueReducer
});

export default rootReducers;