import React from 'react';
import { Route, HashRouter } from 'react-router-dom';
import store from './services/redux/store';
import { Provider } from 'react-redux';
import globalConst from './services/globalConst';
import PageSpinner from './components/Loader';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/style.scss';
import ScrollToTop from './components/ScrollTop';
const AutoTagging = React.lazy(() => import('./autoTagging'));
const Metafields = React.lazy(() => import('./metafields'));
const Timer = React.lazy(() => import('./timer'));




function App() {
  return (
    <HashRouter>
      <ScrollToTop>
        <React.Suspense fallback={<PageSpinner />}>
          <Provider store={store}>
            <Route path={`/${globalConst.appCode.autoTagging}/:module`} component={AutoTagging} />
            <Route path={`/${globalConst.appCode.metafields}/:module`} component={Metafields} />
            <Route path={`/${globalConst.appCode.timer}/:module`} component={Timer} />
          </Provider>
        </React.Suspense>
      </ScrollToTop>
    </HashRouter>

  );
}

export default App;
